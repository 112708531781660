@import "./variable.scss";
@import "./media.scss";

#parteners {
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 90px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;

    .heading {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      h1 {
        font-family: "raboto";
        font-size: 3rem;
        font-weight: 500;
        text-transform: none;
        color: #000000;
        text-align: start;
        text-transform: uppercase;
      }

      h2 {
        font-family: "raboto";
        font-size: 3rem;
        font-weight: 500;
        text-transform: none;
        color: #000000;
        text-align: start;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 30px 5rem;
      width: 100%;
      flex-wrap: wrap;
      opacity: 0.8;
      @include md {
        flex-wrap: wrap;
        justify-content: center;
      }
      img {
        height: 50px;
        filter: invert(1);
      }
    }
  }
}
