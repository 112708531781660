@import "./media.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#banner {
  align-items: center;
  display: flex;
  justify-content: start;
  position: relative;
  z-index: 1;
  flex-direction: column;
  height: 190vh;

  @include md {
    padding-top: 55px;
  }

  .container {
    max-width: 990px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: center;
    text-align: center;
    place-items: center;
    padding: 190px 20px;

    @include md {
      grid-template-columns: 1fr;
      padding: 20px 20px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .heading {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        h1 {
          font-family: "raboto";
          font-size: 54px;
          font-weight: 700;
          line-height: 50px;
          text-transform: none;
          color: white;
          max-width: 880px;
          text-align: center;
          margin: auto;
          font-family: "raboto";
          font-size: 40px;
          font-weight: 500;
          line-height: 50px;
          text-transform: none;
          color: white;
          text-align: center;
          font-size: clamp(4rem, 7vw, 5.5rem);
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          filter: drop-shadow(2px 6px 4px #00000087);
          line-height: 105%;
          -webkit-filter: drop-shadow(2px 6px 4px #00000087);
          margin-bottom: 0;
          margin-top: 0;
          position: relative;
          margin: 0 auto 0px;
          font-weight: 700;
          background-image: -webkit-linear-gradient(
            top,
            #efd100,
            #e2a233 19%,
            #f0c328 30%,
            #fff1a3 43%,
            #ffe13e 50%,
            #fff 51%,
            #fff
          );
          background-size: 1em 5.5em;
          color: #f8b700;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @include md {
            max-width: 100%;
            width: 100%;
            background-size: 1em 9.5em;
          }
        }

        p {
          font-family: "poppins";
          text-align: start;
          color: rgb(255, 255, 255);
          text-decoration: none;
          white-space: normal;
          line-height: 24px;
          font-weight: 300;
          font-size: 16px;
          text-align: center;
          margin: auto;

          @include mobile {
            max-width: 100%;
            width: 100%;
          }
        }

        .flex__ {
          display: flex;
          gap: 20px;
          justify-content: center;
          margin: auto;

          a {
            background-color: #ffb32c;
            font-family: "roboto-light";
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            color: black;
            font-weight: 600;
            font-size: 16px;
            border-color: transparent;
            padding: 12px 28px;
            margin-top: 10px;
            border-radius: 0.3rem;

            @include mobile {
              padding: 12px 25px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .para {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 40px;
        padding-left: 30px;

        @include md {
          gap: 15px;
        }

        p {
          text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
          color: white;
          font-size: 34px;
          font-family: "font-1";
          font-weight: 700;
          letter-spacing: 4.2px;
          line-height: 42.4px;

          @include md {
            letter-spacing: 1.2px;
          }
        }
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 100px;

      @include md {
        justify-content: flex-start;
      }

      img {
        width: 484px;
        object-fit: cover;

        @include md {
          width: 100%;
        }
      }
    }
  }
}
