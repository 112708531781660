@import "./variable.scss";
@import "./media.scss";

#about {
  position: relative;
  z-index: 1;
  background-color: white;
  background-color: #fff;
  box-shadow: 0 0 5px -1px #0000001f;

  .container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px 20px;

    @include md {
      grid-template-columns: 1fr;
      row-gap: 30px;
      padding: 90px 20px;
      grid-gap: 40px;
    }

    .img {
      width: 100%;
      text-align: end;
      display: flex;
      align-items: center;
      justify-content: end;

      @include md {
        justify-content: start !important;
      }

      img {
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 20px;

      .heading {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        h1 {
          font-family: "roboto-light";
          font-size: 40px;
          font-weight: 300;
          line-height: 50px;
          text-transform: none;
          color: #000000;
          text-align: start;
        }

        h2 {
          font-family: "raboto";
          font-size: 3rem;
          font-weight: 500;
          text-transform: none;
          color: #000000;
          text-align: start;
          text-transform: uppercase;
        }
      }

      p {
        font-family: "open";
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-transform: none;
        color: #999;
      }

      a {
        background-color: #ffb32c;
        font-family: "roboto-light";
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        color: black;
        font-weight: 600;
        font-size: 16px;
        border-color: transparent;
        padding: 14px 28px;
        margin-top: 0px;
        border-radius: 0.3rem;
      }
    }
  }
}
