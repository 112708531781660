/* @import "./media.scss"; */

.bac-main {
  position: relative;
}
.bac-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../assests/bg/24783.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-attachment: s;
}
