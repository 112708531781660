@import "./variable.scss";
@import "./media.scss";

#timer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assests/bg/bc-5.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 90px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .heading {
      h1 {
        font-family: "raboto";
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        text-transform: none;
        color: white;
        text-align: center;
        font-size: clamp(3rem, 6vw, 3rem);

        text-align: center;
        color: #fff;
        text-transform: uppercase;
        filter: drop-shadow(2px 6px 4px #00000087);
        line-height: 105%;
        -webkit-filter: drop-shadow(2px 6px 4px #00000087);
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        margin: 0 auto 20px;
        font-weight: 700;
        background-image: -webkit-linear-gradient(
          top,
          #efd100,
          #e2a233 19%,
          #f0c328 30%,
          #fff1a3 43%,
          #ffe13e 50%,
          #fff 51%,
          #fff
        );
        background-size: 1em 2.5em;
        color: #f8b700;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include md {
          background-size: 1em 5.5em;
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #fff;
        opacity: 0.7;
        max-width: 70%;
        font-family: "Poppins";
        margin-top: 5px;
        margin: 5px auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      row-gap: 50px;

      .time-boxes {
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        width: 100%;
        flex-wrap: wrap;

        @include md {
          gap: 20px;
        }

        .box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
          align-items: center;
          border: 4px solid #ffffff;
          padding: 20px 35px;

          h1 {
            font-size: 60px;
            font-weight: 700;
            color: white;
            font-family: "raboto";
          }

          span {
            font-size: 16px;
            font-weight: 700;
            color: white;
            font-family: "raboto";
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 30px;

        .btn {
          background-color: #252525;
          font-family: "raboto";
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
          font-size: 14px;
          padding: 12px 22px;
          font-weight: 400;
          color: white;
          border-radius: 0.3rem;
          cursor: pointer;
          border: 0px;
        }

        .bottom-heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 20px;

          span {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 30px;
            text-align: center;
            font-family: "raboto";
            font-weight: 700;
            font-style: normal;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
          }

          h1 {
            font-size: 60px;
            color: #ffffff;
            line-height: 60px;
            text-align: center;
            font-family: "raboto";
            font-weight: 700;
            font-style: normal;
            @include md {
              font-size: 40px;
            }
          }

          p {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 30px;
            text-align: center;
            font-family: "raboto";
            font-weight: 700;
            font-style: normal;
          }
        }
      }
    }
  }
}
