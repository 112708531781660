@import "./variable.scss";
@import "./media.scss";

#bit {
  background-color: #ffc40c;

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 90px 20px;

    .heading {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      h1 {
        font-family: "roboto-light";
        font-size: 40px;
        font-weight: 300;
        line-height: 50px;
        text-transform: none;
        width: 100%;
        text-align: start;
        color: #000000;
      }

      h2 {
        font-family: "raboto";
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        text-transform: none;
        width: 100%;
        text-align: start;

        color: #000000;
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 50px 40px;
      padding: 50px 0px;

      @include lg {
        grid-template-columns: 1fr 1fr;
      }

      @include md {
        grid-template-columns: 1fr;
      }

      .box-wrapper {
        .box {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          row-gap: 15px;

          h2 {
            font-family: "raboto";
            font-size: 20px;
            font-weight: 700;
            text-transform: none;
            text-align: start;
            color: #000000;
            max-width: 400px;
            @include md {
              max-width: 100%;
              width: 100%;
            }
          }

          p {
            font-family: "open";
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            text-transform: none;
            color: #999;
            text-align: start;
            max-width: 500px;

            @include md {
                grid-template-columns: 1fr;
            }

            .box-wrapper {


                .box {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    row-gap: 15px;

                    h2 {
                        font-family: "raboto";
                        font-size: 20px;
                        font-weight: 700;
                        text-transform: none;
                        text-align: start;
                        color: #000000;
                        max-width: 400px;
                        @include md {
                            max-width: 100%;
                            width: 100%;
                        }
                    }

                    p {
                        font-family: "open";
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 26px;
                        text-transform: none;
                        color: #999;
                        text-align: start;
                        max-width: 500px;

                        @include md {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
          }
        }
      }
    }
  }
}
