@import "./variable.scss";
@import "./media.scss";

#token {
  background: #ffb32c;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(../assests/layer.png) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 90px 20px;
    display: grid;
    grid-template-columns: 1.2fr 1fr;

    @include md {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .first {
      max-width: 470px;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      @include md {
        order: 2;
      }
      @include mobile {
        max-width: 375px;
      }

      @media (max-width: 420px) {
        max-width: 355px;
      }
      @media (max-width: 394px) {
        max-width: 315px;
      }
      @media (max-width: 350px) {
        max-width: 300px;
      }

      .percent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px 50px;

        .h {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          justify-content: start;

          p {
            font-size: 15px;
            line-height: 22px;
            color: black;
            font-weight: 500;
            margin-bottom: 5px;
          }

          span {
            width: 12px;
            height: 4px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:nth-child(1) {
            span {
              background: #663702;
            }
          }

          &:nth-child(2) {
            span {
              background: #dca859;
            }
          }

          &:nth-child(3) {
            span {
              background: #fae076;
            }
          }

          &:nth-child(4) {
            span {
              background: #c3944d;
            }
          }
        }
      }
    }

    .second {
      @include md {
        order: 1;
      }
      h2 {
        font-size: 18px;
        width: 50%;
        color: black;
        font-family: "raboto";
        font-weight: 500;

        @include mobile {
          font-size: 16px;
        }
      }

      span {
        font-size: 18px;
        line-height: 22px;
        color: black;

        @include mobile {
          font-size: 13px;
        }
      }

      .heading {
        text-align: center;
        padding: 60px 0px;

        h1 {
          font-size: clamp(10%, 12vw, 3rem);
          line-height: 45px;
          color: black;
          font-weight: 400;
          text-align: start;
          font-family: "raboto";
        }
      }

      .detial {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        .d {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h1 {
            font-size: 18px;
            width: 50%;
            color: black;
            font-family: "raboto";
            font-weight: 500;

            @include mobile {
              font-size: 16px;
            }
          }
        }
      }
      .head__ {
        color: black;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 18px;
      }
      .datalist {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        .item {
          box-shadow: 0 0 40px rgba(8, 0, 17, 0.1);
          border-radius: 0.3rem;
          background-color: #fdbb48;
          padding: 18px 20px;
          font-size: 14px;
          line-height: 1;
          color: #888a8b;
          /* margin: 30px 0; */
          /* display: flex; */
          align-items: center;
          justify-content: space-between;
        }
        .box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
        }
      }

      .data-info {
        box-shadow: 0 0 40px rgba(8, 0, 17, 0.1);
        border-radius: 0.3rem;
        background-color: #fdbb48;
        padding: 18px 20px;
        font-size: 14px;
        line-height: 1;
        color: #888a8b;
        margin: 30px 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include md {
          flex-direction: column;
          gap: 20px;
          align-items: start;
        }

        .item {
          h3 {
            font-size: 20px;
          }

          p {
            font-size: 15px;
            line-height: 22px;
            color: #202121;
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 40px;

        a {
          background-color: #141414;
          font-family: "raboto";
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
          font-size: 14px;
          padding: 12px 22px;
          font-weight: 400;
          width: 130px;
          color: #ffffff;
          border-radius: 0.3rem;
          border: 0px;
        }
      }
    }
  }
}
