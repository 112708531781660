@import "./variable.scss";
@import "./media.scss";

#about {
  position: relative;
  z-index: 1;
  background-color: white;
  background-color: #fff;
  box-shadow: 0 0 5px -1px #0000001f;

  .container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px 20px;
    grid-gap: 50px;

    @include md {
      grid-template-columns: 1fr;
      padding: 90px 20px;
    }

    .img1 {
      display: flex;
      align-items: center;
      justify-content: start;

      img {
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 20px;

      .heading {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        h1 {
          font-family: "raboto";
          font-size: 3rem;
          font-weight: 500;
          text-transform: none;
          color: #000000;
          text-align: start;
          text-transform: uppercase;
        }

        h2 {
          font-family: "raboto";
          font-size: 40px;
          font-weight: 500;
          line-height: 50px;
          text-transform: none;
          color: #000000;
          text-align: start;
        }

        h3 {
          font-family: "roboto-light";
          font-size: 30px;
          font-weight: 300;
          line-height: 40px;
          text-transform: none;
          color: #000000;
          text-align: start;
        }
      }

      p {
        font-family: "open";
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-transform: none;
        color: #999;
      }

      a {
        background-color: #ffc40c;
        font-family: "roboto-light";
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        color: black;
        font-weight: 300;
        font-size: 18px;
        border-color: transparent;
        padding: 16px 28px;
        margin-top: 30px;
      }
    }
  }
}
.goog-te-gadget-simple {
  background-color: #fff;
  border-left: 1px solid #d5d5d5;
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #d5d5d5;
  font-size: 10pt;
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
  position: fixed;
  bottom: 13px;
  left: 13px;
  z-index: 9999;
  border: 0 !important;
  padding: 6px !important;
  border-radius: 4px;

  background-color: #ffb32c !important;
}
a.VIpgJd-ZVi9od-xl07Ob-lTBxed > span:nth-child(2) {
  display: none;
}

.goog-te-gadget img {
  vertical-align: middle;
  border: none;
  display: none;
}
a.VIpgJd-ZVi9od-xl07Ob-lTBxed {
}
