@import "./variable.scss";
@import "./media.scss";

#footer {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assests/bg/24783.jpg");
    background-size: 180%;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;
    @include md {
      background-size: cover;
    }
  }

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 160px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0px;

      @include sm {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
      }

      .logo {
        justify-self: start;
        display: flex;
        max-width: 150px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          img {
            width: 100%;
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      padding: 20px 0px;
      justify-content: center;

      @include sm {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
      }

      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        a {
          svg {
            fill: white;
            font-size: 30px;
          }
        }
      }
    }
  }
}
