@import "./variable.scss";
@import "./media.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#section-2 {
  position: relative;
  z-index: 1;
  background-color: #f2f2f2;

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 90px 20px;

    .heading {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      h1 {
        font-family: "roboto-light";
        font-size: 40px;
        font-weight: 300;
        line-height: 50px;
        text-transform: none;
        color: #000000;
        text-align: center;

        @include md {
          max-width: 100%;
          width: 100%;
        }
      }

      h2 {
        font-family: "raboto";
        font-size: 3rem;
        font-weight: 500;
        line-height: 50px;
        text-transform: none;
        text-align: center;
        color: #000000;
        max-width: 700px;
        @include md {
          max-width: 100%;
          width: 100%;
        }
      }

      p {
        font-family: "open";
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-transform: none;
        color: #999;
        text-align: center;
        max-width: 600px;

        @include md {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px 40px;
      padding: 50px 0px;

      @include lg {
        grid-template-columns: 1fr 1fr;
      }

      @include md {
        grid-template-columns: 1fr;
      }

      .box-wrapper {
        background-color: #fff;
        background-color: #fff;

        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 15px;
          padding: 40px 25px;

          h2 {
            font-family: "raboto";
            font-size: 25px;
            font-weight: 600;
            text-transform: none;
            color: #000000;
            max-width: 400px;
            text-align: center;

            @include md {
              max-width: 100%;
              width: 100%;
            }
          }

          h3 {
            font-family: "roboto-light";
            font-size: 20px;
            font-weight: 300;
            text-transform: none;
            text-align: center;
            color: #000000;
            max-width: 400px;

            @include md {
              max-width: 100%;
              width: 100%;
            }
          }

          p {
            font-family: "open";
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            text-transform: none;
            color: #999;
            text-align: center;
            max-width: 500px;

            @include md {
              max-width: 100%;
              width: 100%;
            }
          }

          a {
            background-color: #141414;
            font-family: "roboto-light";
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            font-size: 16px;
            padding: 16px 28px;
            color: #ffffff;
            margin-top: 30px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
