@import "./variable.scss";
@import "./media.scss";

html {
  scroll-behavior: smooth;
}

header {
  width: 100%;
  transition: 0.3s all;

  .outer {
    position: relative;

    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 12px 20px;

      .nav__grid {
        display: grid;

        grid-template-columns: 2fr 3fr 0.5fr;
        position: relative;
        align-items: end;
        justify-content: end;

        @include mobile {
          flex-direction: column;
          align-self: center;
          justify-content: center;
          row-gap: 20px;
          display: flex;
        }

        .logo {
          justify-self: start;
          display: flex;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          img {
            width: 100%;
            max-width: 65px;
          }
        }

        nav {
          ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            list-style: none;
            gap: 30px;

            > li {
              margin: 0.5rem 0px !important;
              display: flex;
              position: relative;
              transition: 0.3s all;
              font-size: 18px;
              font-family: "open";

              align-items: center;
              justify-content: center;

              > a {
                transition: 0.3s all;
                text-transform: capitalize;

                font-family: "open";
                color: #ffffff;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 300;
              }
            }
          }
        }

        .nav__btns {
          display: flex;
          align-items: center;
          gap: 1.2rem;
          justify-content: end;
          font-family: "Zen Dots", cursive;

          @include mobile {
            justify-content: space-between;
            gap: 0px;
            width: 100%;
          }

          .btn {
            background-color: #141414;
            font-family: "raboto";
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            font-size: 14px;
            padding: 12px 22px;
            font-weight: 400;
            width: 130px;
            color: #ffffff;
            border-radius: 0.3rem;
            border: 0px;
            cursor: pointer;
            text-align: center;
            @include md {
              display: none;
            }
            @include mobile {
              padding: 14px 25px;
              width: auto;
            }

            span {
              transition: inherit;

              font-weight: 700;
              letter-spacing: 0.2em;
              font-style: normal;
              display: initial;
              font-size: 21px;
              font-family: "font-2";
            }
          }

          .active {
            background-color: #ffb32c !important;
            color: black !important;
          }

          i {
            padding: 3px 3px !important;
          }

          .logo-icons {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            color: white;
            color: white;

            @include xtablet7 {
              gap: 0.6rem;
            }

            .icon {
              border-radius: 50%;
              transition: all 0.3s ease-in-out;

              a {
                align-items: center;
                background: #222;
                border-radius: 4px;
                color: #fff;
                cursor: pointer;
                display: flex;
                font-size: 27px;
                justify-content: center;
                padding: 5px;
                transition: all 0.5s ease-in-out;

                svg {
                  padding: 1px;

                  @include xtablet7 {
                    padding: 2px;
                  }

                  @include xtablet13 {
                    padding: 3px;
                  }
                }
              }
            }

            .bars {
              img {
                max-width: 20px;
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.auto_scroll {
  position: fixed;
  bottom: 10%;
  right: 5%;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 1001;
  transform: scale(0);

  @include display {
    right: 1.4%;
  }

  @include coloum {
    right: 16.7%;
  }

  > i {
    width: 50px;
    background: #ffb32c !important;
    height: 50px;
    display: grid;
    place-content: center;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #363434;
    position: absolute;
    top: 10px;
    left: 5px;
    svg {
      font-size: 2.8rem;

      @include md {
        font-size: 2.5rem;
      }
    }
  }
}

@media (min-width: 992px) {
  header {
    .nav__grid {
      .nav__btns {
        > i {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 992px) {
  header {
    .nav__grid {
      grid-template-columns: 1fr 1.5fr !important;

      nav {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 100;
        overflow: hidden;
        box-shadow: 0 0 5px black;

        ul {
          justify-content: start !important;
          transition: 0.3s all ease-out;
          flex-direction: column;
          margin-top: -200%;
          border-bottom: 1px solid #70696945;
          z-index: 1000;
          align-items: flex-start !important;
          padding: 0px 20px;
          font-size: 1.4rem;
          background: #141414;

          li {
            margin-right: unset;
            margin-bottom: 15px;
          }
        }

        .ul__active {
          margin-top: 0% !important;
        }
      }

      .nav__btns {
        > i {
          width: 50px;
          background: #ffb32c !important;
          height: 50px;
          display: grid;
          place-content: center;
          border-radius: 5px;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
          pointer-events: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #363434;
          position: absolute;
          top: 10px;
          left: 5px;
        }
      }
    }
  }
}
